/* @import "compass/reset"; */
 .loaderborder {
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 display: block;
	 width: 360px;
	 height: 360px;
	 margin-left: -180px;
	 margin-top: -180px;
	 z-index: 9999;
     opacity: 1;
	 background-image: radial-gradient(circle 360px at 50% 50%, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 72px, rgba(255, 255, 255, 1) 180px);
}
 .loader {
	 background-color: white;
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 margin-left: -180px;
	 margin-top: -180px;
	 width: 360px;
	 height: 360px;
	 border-radius: 50%;
     opacity: 1;
	 z-index: 999;
	 overflow: hidden;
}
 .loader .center {
	 z-index: 999;
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 margin-left: -72px;
	 margin-top: -72px;
	 width: 144px;
	 height: 144px;
	 border-radius: 50%;
	 background-image: radial-gradient(circle 144px at 50% 50%, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 36px, rgba(255, 255, 255, 0) 72px);
}
 .loader .container {
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 width: 180px;
	 height: 1px;
	 transform-origin: left 50%;
}
 .loader .container .star {
	 border-radius: 8px;
	 background-color: white;
	 animation-name: hyperspace;
	 animation-duration: 1000ms;
	 animation-timing-function: linear;
	 animation-iteration-count: infinite;
}
 .container:nth-child(0) {
	 transform: rotate(247deg);
}
 .container:nth-child(0) .star {
	 animation-delay: 246ms;
	 background-color: #ec5c57;
	 width: 34px;
	 height: 2.8333333333px;
}
 .container:nth-child(1) {
	 transform: rotate(253deg);
}
 .container:nth-child(1) .star {
	 animation-delay: 282ms;
	 background-color: #ec5c57;
	 width: 16px;
	 height: 1.3333333333px;
}
 .container:nth-child(2) {
	 transform: rotate(39deg);
}
 .container:nth-child(2) .star {
	 animation-delay: 864ms;
	 background-color: #ec5c57;
	 width: 9px;
	 height: 0.75px;
}
 .container:nth-child(3) {
	 transform: rotate(140deg);
}
 .container:nth-child(3) .star {
	 animation-delay: 381ms;
	 background-color: #ec5c57;
	 width: 10px;
	 height: 0.8333333333px;
}
 .container:nth-child(4) {
	 transform: rotate(85deg);
}
 .container:nth-child(4) .star {
	 animation-delay: 572ms;
	 background-color: #ec5c57;
	 width: 17px;
	 height: 1.4166666667px;
}
 .container:nth-child(5) {
	 transform: rotate(143deg);
}
 .container:nth-child(5) .star {
	 animation-delay: 949ms;
	 background-color: #ec5c57;
	 width: 34px;
	 height: 2.8333333333px;
}
 .container:nth-child(6) {
	 transform: rotate(352deg);
}
 .container:nth-child(6) .star {
	 animation-delay: 36ms;
	 background-color: #ec5c57;
	 width: 32px;
	 height: 2.6666666667px;
}
 .container:nth-child(7) {
	 transform: rotate(69deg);
}
 .container:nth-child(7) .star {
	 animation-delay: 114ms;
	 background-color: #ec5c57;
	 width: 28px;
	 height: 2.3333333333px;
}
 .container:nth-child(8) {
	 transform: rotate(11deg);
}
 .container:nth-child(8) .star {
	 animation-delay: 708ms;
	 background-color: #ec5c57;
	 width: 9px;
	 height: 0.75px;
}
 .container:nth-child(9) {
	 transform: rotate(73deg);
}
 .container:nth-child(9) .star {
	 animation-delay: 680ms;
	 background-color: #ec5c57;
	 width: 29px;
	 height: 2.4166666667px;
}
 .container:nth-child(10) {
	 transform: rotate(130deg);
}
 .container:nth-child(10) .star {
	 animation-delay: 225ms;
	 background-color: #ec5c57;
	 width: 34px;
	 height: 2.8333333333px;
}
 .container:nth-child(11) {
	 transform: rotate(93deg);
}
 .container:nth-child(11) .star {
	 animation-delay: 34ms;
	 background-color: #ec5c57;
	 width: 24px;
	 height: 2px;
}
 .container:nth-child(12) {
	 transform: rotate(307deg);
}
 .container:nth-child(12) .star {
	 animation-delay: 533ms;
	 background-color: #ec5c57;
	 width: 11px;
	 height: 0.9166666667px;
}
 .container:nth-child(13) {
	 transform: rotate(349deg);
}
 .container:nth-child(13) .star {
	 animation-delay: 745ms;
	 background-color: #ec5c57;
	 width: 12px;
	 height: 1px;
}
 .container:nth-child(14) {
	 transform: rotate(17deg);
}
 .container:nth-child(14) .star {
	 animation-delay: 910ms;
	 background-color: #ec5c57;
	 width: 14px;
	 height: 1.1666666667px;
}
 .container:nth-child(15) {
	 transform: rotate(69deg);
}
 .container:nth-child(15) .star {
	 animation-delay: 544ms;
	 background-color: #ec5c57;
	 width: 16px;
	 height: 1.3333333333px;
}
 .container:nth-child(16) {
	 transform: rotate(172deg);
}
 .container:nth-child(16) .star {
	 animation-delay: 464ms;
	 background-color: #ec5c57;
	 width: 9px;
	 height: 0.75px;
}
 .container:nth-child(17) {
	 transform: rotate(352deg);
}
 .container:nth-child(17) .star {
	 animation-delay: 655ms;
	 background-color: #ec5c57;
	 width: 35px;
	 height: 2.9166666667px;
}
 .container:nth-child(18) {
	 transform: rotate(27deg);
}
 .container:nth-child(18) .star {
	 animation-delay: 682ms;
	 background-color: #ec5c57;
	 width: 31px;
	 height: 2.5833333333px;
}
 .container:nth-child(19) {
	 transform: rotate(145deg);
}
 .container:nth-child(19) .star {
	 animation-delay: 94ms;
	 background-color: #ec5c57;
	 width: 29px;
	 height: 2.4166666667px;
}
 .container:nth-child(20) {
	 transform: rotate(271deg);
}
 .container:nth-child(20) .star {
	 animation-delay: 374ms;
	 background-color: #ec5c57;
	 width: 12px;
	 height: 1px;
}
 .container:nth-child(21) {
	 transform: rotate(167deg);
}
 .container:nth-child(21) .star {
	 animation-delay: 429ms;
	 background-color: #ec5c57;
	 width: 21px;
	 height: 1.75px;
}
 .container:nth-child(22) {
	 transform: rotate(141deg);
}
 .container:nth-child(22) .star {
	 animation-delay: 70ms;
	 background-color: #ec5c57;
	 width: 34px;
	 height: 2.8333333333px;
}
 .container:nth-child(23) {
	 transform: rotate(183deg);
}
 .container:nth-child(23) .star {
	 animation-delay: 864ms;
	 background-color: #ec5c57;
	 width: 33px;
	 height: 2.75px;
}
 .container:nth-child(24) {
	 transform: rotate(191deg);
}
 .container:nth-child(24) .star {
	 animation-delay: 626ms;
	 background-color: #ec5c57;
	 width: 15px;
	 height: 1.25px;
}
 .container:nth-child(25) {
	 transform: rotate(207deg);
}
 .container:nth-child(25) .star {
	 animation-delay: 24ms;
	 background-color: #ec5c57;
	 width: 30px;
	 height: 2.5px;
}
 .container:nth-child(26) {
	 transform: rotate(166deg);
}
 .container:nth-child(26) .star {
	 animation-delay: 593ms;
	 background-color: #ec5c57;
	 width: 15px;
	 height: 1.25px;
}
 .container:nth-child(27) {
	 transform: rotate(266deg);
}
 .container:nth-child(27) .star {
	 animation-delay: 802ms;
	 background-color: #ec5c57;
	 width: 14px;
	 height: 1.1666666667px;
}
 .container:nth-child(28) {
	 transform: rotate(330deg);
}
 .container:nth-child(28) .star {
	 animation-delay: 668ms;
	 background-color: #ec5c57;
	 width: 30px;
	 height: 2.5px;
}
 .container:nth-child(29) {
	 transform: rotate(300deg);
}
 .container:nth-child(29) .star {
	 animation-delay: 546ms;
	 background-color: #ec5c57;
	 width: 16px;
	 height: 1.3333333333px;
}
 .container:nth-child(30) {
	 transform: rotate(227deg);
}
 .container:nth-child(30) .star {
	 animation-delay: 616ms;
	 background-color: #ec5c57;
	 width: 16px;
	 height: 1.3333333333px;
}
 .container:nth-child(31) {
	 transform: rotate(231deg);
}
 .container:nth-child(31) .star {
	 animation-delay: 154ms;
	 background-color: #ec5c57;
	 width: 19px;
	 height: 1.5833333333px;
}
 .container:nth-child(32) {
	 transform: rotate(78deg);
}
 .container:nth-child(32) .star {
	 animation-delay: 556ms;
	 background-color: #ec5c57;
	 width: 33px;
	 height: 2.75px;
}
 .container:nth-child(33) {
	 transform: rotate(276deg);
}
 .container:nth-child(33) .star {
	 animation-delay: 700ms;
	 background-color: #ec5c57;
	 width: 11px;
	 height: 0.9166666667px;
}
 .container:nth-child(34) {
	 transform: rotate(349deg);
}
 .container:nth-child(34) .star {
	 animation-delay: 773ms;
	 background-color: #ec5c57;
	 width: 25px;
	 height: 2.0833333333px;
}
 .container:nth-child(35) {
	 transform: rotate(34deg);
}
 .container:nth-child(35) .star {
	 animation-delay: 290ms;
	 background-color: #ec5c57;
	 width: 25px;
	 height: 2.0833333333px;
}
 .container:nth-child(36) {
	 transform: rotate(204deg);
}
 .container:nth-child(36) .star {
	 animation-delay: 700ms;
	 background-color: #ec5c57;
	 width: 16px;
	 height: 1.3333333333px;
}
 .container:nth-child(37) {
	 transform: rotate(5deg);
}
 .container:nth-child(37) .star {
	 animation-delay: 216ms;
	 background-color: #ec5c57;
	 width: 34px;
	 height: 2.8333333333px;
}
 .container:nth-child(38) {
	 transform: rotate(194deg);
}
 .container:nth-child(38) .star {
	 animation-delay: 842ms;
	 background-color: #ec5c57;
	 width: 33px;
	 height: 2.75px;
}
 .container:nth-child(39) {
	 transform: rotate(175deg);
}
 .container:nth-child(39) .star {
	 animation-delay: 836ms;
	 background-color: #ec5c57;
	 width: 10px;
	 height: 0.8333333333px;
}
 .container:nth-child(40) {
	 transform: rotate(331deg);
}
 .container:nth-child(40) .star {
	 animation-delay: 754ms;
	 background-color: #ec5c57;
	 width: 13px;
	 height: 1.0833333333px;
}
 .container:nth-child(41) {
	 transform: rotate(267deg);
}
 .container:nth-child(41) .star {
	 animation-delay: 375ms;
	 background-color: #ec5c57;
	 width: 24px;
	 height: 2px;
}
 .container:nth-child(42) {
	 transform: rotate(75deg);
}
 .container:nth-child(42) .star {
	 animation-delay: 838ms;
	 background-color: #ec5c57;
	 width: 17px;
	 height: 1.4166666667px;
}
 .container:nth-child(43) {
	 transform: rotate(74deg);
}
 .container:nth-child(43) .star {
	 animation-delay: 493ms;
	 background-color: #ec5c57;
	 width: 35px;
	 height: 2.9166666667px;
}
 .container:nth-child(44) {
	 transform: rotate(357deg);
}
 .container:nth-child(44) .star {
	 animation-delay: 463ms;
	 background-color: #ec5c57;
	 width: 33px;
	 height: 2.75px;
}
 .container:nth-child(45) {
	 transform: rotate(256deg);
}
 .container:nth-child(45) .star {
	 animation-delay: 482ms;
	 background-color: #ec5c57;
	 width: 35px;
	 height: 2.9166666667px;
}
 .container:nth-child(46) {
	 transform: rotate(258deg);
}
 .container:nth-child(46) .star {
	 animation-delay: 248ms;
	 background-color: #ec5c57;
	 width: 16px;
	 height: 1.3333333333px;
}
 .container:nth-child(47) {
	 transform: rotate(185deg);
}
 .container:nth-child(47) .star {
	 animation-delay: 267ms;
	 background-color: #ec5c57;
	 width: 35px;
	 height: 2.9166666667px;
}
 .container:nth-child(48) {
	 transform: rotate(353deg);
}
 .container:nth-child(48) .star {
	 animation-delay: 563ms;
	 background-color: #ec5c57;
	 width: 9px;
	 height: 0.75px;
}
 .container:nth-child(49) {
	 transform: rotate(126deg);
}
 .container:nth-child(49) .star {
	 animation-delay: 347ms;
	 background-color: #ec5c57;
	 width: 22px;
	 height: 1.8333333333px;
}
 .container:nth-child(50) {
	 transform: rotate(305deg);
}
 .container:nth-child(50) .star {
	 animation-delay: 949ms;
	 background-color: #ec5c57;
	 width: 24px;
	 height: 2px;
}
 .container:nth-child(51) {
	 transform: rotate(333deg);
}
 .container:nth-child(51) .star {
	 animation-delay: 680ms;
	 background-color: #ec5c57;
	 width: 17px;
	 height: 1.4166666667px;
}
 .container:nth-child(52) {
	 transform: rotate(64deg);
}
 .container:nth-child(52) .star {
	 animation-delay: 553ms;
	 background-color: #ec5c57;
	 width: 11px;
	 height: 0.9166666667px;
}
 .container:nth-child(53) {
	 transform: rotate(4deg);
}
 .container:nth-child(53) .star {
	 animation-delay: 608ms;
	 background-color: #ec5c57;
	 width: 13px;
	 height: 1.0833333333px;
}
 .container:nth-child(54) {
	 transform: rotate(243deg);
}
 .container:nth-child(54) .star {
	 animation-delay: 843ms;
	 background-color: #ec5c57;
	 width: 22px;
	 height: 1.8333333333px;
}
 .container:nth-child(55) {
	 transform: rotate(85deg);
}
 .container:nth-child(55) .star {
	 animation-delay: 166ms;
	 background-color: #ec5c57;
	 width: 17px;
	 height: 1.4166666667px;
}
 .container:nth-child(56) {
	 transform: rotate(160deg);
}
 .container:nth-child(56) .star {
	 animation-delay: 702ms;
	 background-color: #ec5c57;
	 width: 18px;
	 height: 1.5px;
}
 @keyframes hyperspace {
	 0% {
		 margin-left: 0px;
	}
	 100% {
		 margin-left: 198px;
	}
}